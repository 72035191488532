/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import * as React from 'react';
import { SERVER_URL } from 'Constants';
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchOneToManyFunc,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import CurriculumSupportInstitutionEntity from 'Models/Security/Acl/CurriculumSupportInstitutionEntity';
import InstitutionAdminInstitutionEntity from 'Models/Security/Acl/InstitutionAdminInstitutionEntity';
import VisitorsInstitutionEntity from 'Models/Security/Acl/VisitorsInstitutionEntity';
import AdminInstitutionEntity from 'Models/Security/Acl/AdminInstitutionEntity';
import StudentAdvisorInstitutionEntity from 'Models/Security/Acl/StudentAdvisorInstitutionEntity';
import UserInstitutionEntity from 'Models/Security/Acl/UserInstitutionEntity';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IInstitutionEntityAttributes extends IModelAttributes {
	registerBackgroundId: string;
	registerBackground: Blob;
	primaryColour: string;
	unitIncrementAmount: number;
	fullTimeUnitCount: number;
	code: string;
	loginBackgroundId: string;
	loginBackground: Blob;
	secondaryColour: string;
	allowMulticampusSelection: boolean;
	subdomain: string;
	name: string;
	logoId: string;
	logo: Blob;
	successColour: string;
	warningColour: string;
	contactEmail: string;
	cricosNumber: string;

	campuss: Array<
		| Models.CampusEntity
		| Models.ICampusEntityAttributes
	>;
	courseLevels: Array<
		| Models.CourseLevelEntity
		| Models.ICourseLevelEntityAttributes
	>;
	courses: Array<
		| Models.CourseEntity
		| Models.ICourseEntityAttributes
	>;
	curriculumSupports: Array<
		| Models.CurriculumSupportEntity
		| Models.ICurriculumSupportEntityAttributes
	>;
	facultys: Array<
		| Models.FacultyEntity
		| Models.IFacultyEntityAttributes
	>;
	graduateLevelss: Array<
		| Models.GraduateLevelEntity
		| Models.IGraduateLevelEntityAttributes
	>;
	importJobs: Array<
		| Models.ImportJobEntity
		| Models.IImportJobEntityAttributes
	>;
	institutionAdmins: Array<
		| Models.InstitutionAdminEntity
		| Models.IInstitutionAdminEntityAttributes
	>;
	studentAdvisors: Array<
		| Models.StudentAdvisorEntity
		| Models.IStudentAdvisorEntityAttributes
	>;
	studyPeriodss: Array<
		| Models.StudyPeriodEntity
		| Models.IStudyPeriodEntityAttributes
	>;
	users: Array<
		| Models.UserEntity
		| Models.IUserEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('InstitutionEntity', 'Institution')
// % protected region % [Customise your entity metadata here] end
export default class InstitutionEntity extends Model
	implements IInstitutionEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new CurriculumSupportInstitutionEntity(),
		new InstitutionAdminInstitutionEntity(),
		new VisitorsInstitutionEntity(),
		new AdminInstitutionEntity(),
		new StudentAdvisorInstitutionEntity(),
		new UserInstitutionEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Register Background'] on begin
	@observable
	@attribute<InstitutionEntity, string>({ file: 'registerBackground' })
	@CRUD({
		name: 'Register Background',
		displayType: 'file',
		order: 90,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'registerBackground',
		displayFunction: attr => attr
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt="A Institution" style={{ maxWidth: '300px' }} />
			: 'No File Attached',
	})
	public registerBackgroundId: string;

	@Validators.Required()
	@observable
	public registerBackground: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Register Background'] end

	// % protected region % [Modify props to the crud options here for attribute 'Primary Colour'] on begin
	@Validators.Required()
	@observable
	@attribute<InstitutionEntity, string>()
	@CRUD({
		name: 'Primary Colour',
		displayType: 'textfield',
		order: 20,
		headerColumn: false,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public primaryColour: string;
	// % protected region % [Modify props to the crud options here for attribute 'Primary Colour'] end

	// % protected region % [Modify props to the crud options here for attribute 'Unit Increment Amount'] on begin
	@Validators.Integer()
	@observable
	@attribute<InstitutionEntity, number>()
	@CRUD({
		name: 'Unit Increment Amount',
		displayType: 'textfield',
		order: 60,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public unitIncrementAmount: number;
	// % protected region % [Modify props to the crud options here for attribute 'Unit Increment Amount'] end

	// % protected region % [Modify props to the crud options here for attribute 'Full Time Unit Count'] on begin
	@Validators.Integer()
	@observable
	@attribute<InstitutionEntity, number>()
	@CRUD({
		name: 'Full Time Unit Count',
		displayType: 'textfield',
		order: 50,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public fullTimeUnitCount: number;
	// % protected region % [Modify props to the crud options here for attribute 'Full Time Unit Count'] end

	// % protected region % [Modify props to the crud options here for attribute 'Code'] off begin
	/**
	 * Institution code
	 */
	@Validators.Required()
	@observable
	@attribute<InstitutionEntity, string>()
	@CRUD({
		name: 'Code',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public code: string;
	// % protected region % [Modify props to the crud options here for attribute 'Code'] end

	// % protected region % [Modify props to the crud options here for attribute 'Login Background'] on begin
	@observable
	@attribute<InstitutionEntity, string>({ file: 'loginBackground' })
	@CRUD({
		name: 'Login Background',
		displayType: 'file',
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'loginBackground',
		displayFunction: attr => attr
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt="A Institution" style={{ maxWidth: '300px' }} />
			: 'No File Attached',
	})
	public loginBackgroundId: string;

	@observable
	@Validators.Required()
	public loginBackground: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Login Background'] end

	// % protected region % [Modify props to the crud options here for attribute 'Secondary Colour'] off begin
	@Validators.Required()
	@observable
	@attribute<InstitutionEntity, string>()
	@CRUD({
		name: 'Secondary Colour',
		displayType: 'textfield',
		order: 70,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public secondaryColour: string;
	// % protected region % [Modify props to the crud options here for attribute 'Secondary Colour'] end

	// % protected region % [Modify props to the crud options here for attribute 'Allow multicampus selection'] on begin
	/**
	 * Can courses from this institution be selected/offered at multiple campus's for a single program.
	 */
	@Validators.Required()
	@observable
	@attribute<InstitutionEntity, boolean>()
	@CRUD({
		name: 'Allow multicampus selection',
		displayType: 'checkbox',
		order: 40,
		headerColumn: false,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public allowMulticampusSelection: boolean = false;
	// % protected region % [Modify props to the crud options here for attribute 'Allow multicampus selection'] end

	// % protected region % [Modify props to the crud options here for attribute 'Subdomain'] off begin
	/**
	 * subdomain configured for this institutuiion
	 */
	@Validators.Required()
	@observable
	@attribute<InstitutionEntity, string>()
	@CRUD({
		name: 'Subdomain',
		displayType: 'textfield',
		order: 90,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public subdomain: string;
	// % protected region % [Modify props to the crud options here for attribute 'Subdomain'] end

	// % protected region % [Modify props to the crud options here for attribute 'Name'] on begin
	/**
	 * Institution name
	 */
	@Validators.Required()
	@observable
	@attribute<InstitutionEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 100,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Logo'] on begin
	/**
	 * Institutions logo image
	 */
	@observable
	@attribute<InstitutionEntity, string>({ file: 'logo' })
	@CRUD({
		name: 'Logo',
		displayType: 'file',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseUuid,
		inputProps: {
			imageOnly: true,
		},
		fileAttribute: 'logo',
		displayFunction: attr => attr
			? <img src={`${SERVER_URL}/api/files/${attr}`} alt="Institutions logo image" style={{ maxWidth: '300px' }} />
			: 'No File Attached',
	})
	public logoId: string;

	@Validators.Required()
	@observable
	public logo: Blob;
	// % protected region % [Modify props to the crud options here for attribute 'Logo'] end

	// % protected region % [Modify props to the crud options here for attribute 'Success Colour'] off begin
	@Validators.Required()
	@observable
	@attribute<InstitutionEntity, string>()
	@CRUD({
		name: 'Success Colour',
		displayType: 'textfield',
		order: 120,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public successColour: string;
	// % protected region % [Modify props to the crud options here for attribute 'Success Colour'] end

	// % protected region % [Modify props to the crud options here for attribute 'Warning Colour'] off begin
	@Validators.Required()
	@observable
	@attribute<InstitutionEntity, string>()
	@CRUD({
		name: 'Warning Colour',
		displayType: 'textfield',
		order: 130,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public warningColour: string;
	// % protected region % [Modify props to the crud options here for attribute 'Warning Colour'] end

	// % protected region % [Modify props to the crud options here for attribute 'Contact Email'] off begin
	/**
	 * Publicly contactable email address of the institution
	 */
	@Validators.Email()
	@observable
	@attribute<InstitutionEntity, string>()
	@CRUD({
		name: 'Contact Email',
		displayType: 'textfield',
		order: 140,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public contactEmail: string;
	// % protected region % [Modify props to the crud options here for attribute 'Contact Email'] end

	// % protected region % [Modify props to the crud options here for attribute 'CRICOS Number'] off begin
	@observable
	@attribute<InstitutionEntity, string>()
	@CRUD({
		name: 'CRICOS Number',
		displayType: 'textfield',
		order: 150,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public cricosNumber: string;
	// % protected region % [Modify props to the crud options here for attribute 'CRICOS Number'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Campus'] on begin
		name: 'Campuss',
		displayType: 'reference-multicombobox',
		order: 130,
		referenceTypeFunc: () => Models.CampusEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'campuss',
			oppositeEntity: () => Models.CampusEntity,
		}),
		headerColumn: false,
		// % protected region % [Modify props to the crud options here for reference 'Campus'] end
	})
	public campuss: Models.CampusEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Course Level'] off begin
		name: 'Course Levels',
		displayType: 'reference-multicombobox',
		order: 170,
		referenceTypeFunc: () => Models.CourseLevelEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'courseLevels',
			oppositeEntity: () => Models.CourseLevelEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Course Level'] end
	})
	public courseLevels: Models.CourseLevelEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Course'] on begin
		name: 'Courses',
		displayType: 'reference-multicombobox',
		order: 140,
		referenceTypeFunc: () => Models.CourseEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'courses',
			oppositeEntity: () => Models.CourseEntity,
		}),
		headerColumn: false,
		// % protected region % [Modify props to the crud options here for reference 'Course'] end
	})
	public courses: Models.CourseEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Curriculum Support'] off begin
		name: 'Curriculum Supports',
		displayType: 'reference-multicombobox',
		order: 190,
		referenceTypeFunc: () => Models.CurriculumSupportEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'curriculumSupports',
			oppositeEntity: () => Models.CurriculumSupportEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Curriculum Support'] end
	})
	public curriculumSupports: Models.CurriculumSupportEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Faculty'] on begin
		name: 'Facultys',
		displayType: 'reference-multicombobox',
		order: 150,
		referenceTypeFunc: () => Models.FacultyEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'facultys',
			oppositeEntity: () => Models.FacultyEntity,
		}),
		headerColumn: false,
		// % protected region % [Modify props to the crud options here for reference 'Faculty'] end
	})
	public facultys: Models.FacultyEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Graduate Levels'] on begin
		name: 'Graduate Levelss',
		displayType: 'reference-multicombobox',
		order: 160,
		referenceTypeFunc: () => Models.GraduateLevelEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'graduateLevelss',
			oppositeEntity: () => Models.GraduateLevelEntity,
		}),
		headerColumn: false,
		// % protected region % [Modify props to the crud options here for reference 'Graduate Levels'] end
	})
	public graduateLevelss: Models.GraduateLevelEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Import Job'] off begin
		name: 'Import Jobs',
		displayType: 'reference-multicombobox',
		order: 220,
		referenceTypeFunc: () => Models.ImportJobEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'importJobs',
			oppositeEntity: () => Models.ImportJobEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Import Job'] end
	})
	public importJobs: Models.ImportJobEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Institution Admin'] off begin
		name: 'Institution Admins',
		displayType: 'reference-multicombobox',
		order: 230,
		referenceTypeFunc: () => Models.InstitutionAdminEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'institutionAdmins',
			oppositeEntity: () => Models.InstitutionAdminEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Institution Admin'] end
	})
	public institutionAdmins: Models.InstitutionAdminEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Student Advisor'] off begin
		name: 'Student Advisors',
		displayType: 'reference-multicombobox',
		order: 240,
		referenceTypeFunc: () => Models.StudentAdvisorEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'studentAdvisors',
			oppositeEntity: () => Models.StudentAdvisorEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Student Advisor'] end
	})
	public studentAdvisors: Models.StudentAdvisorEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Study Periods'] on begin
		name: 'Study Periodss',
		displayType: 'reference-multicombobox',
		order: 170,
		referenceTypeFunc: () => Models.StudyPeriodEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'studyPeriodss',
			oppositeEntity: () => Models.StudyPeriodEntity,
		}),
		headerColumn: false,
		// % protected region % [Modify props to the crud options here for reference 'Study Periods'] end
	})
	public studyPeriodss: Models.StudyPeriodEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'User'] on begin
		name: 'Users',
		displayType: 'reference-multicombobox',
		order: 180,
		referenceTypeFunc: () => Models.UserEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'users',
			oppositeEntity: () => Models.UserEntity,
		}),
		headerColumn: false,
		// % protected region % [Modify props to the crud options here for reference 'User'] end
	})
	public users: Models.UserEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IInstitutionEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IInstitutionEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.registerBackground !== undefined) {
				this.registerBackground = attributes.registerBackground;
			}
			if (attributes.registerBackgroundId !== undefined) {
				this.registerBackgroundId = attributes.registerBackgroundId;
			}
			if (attributes.primaryColour !== undefined) {
				this.primaryColour = attributes.primaryColour;
			}
			if (attributes.unitIncrementAmount !== undefined) {
				this.unitIncrementAmount = attributes.unitIncrementAmount;
			}
			if (attributes.fullTimeUnitCount !== undefined) {
				this.fullTimeUnitCount = attributes.fullTimeUnitCount;
			}
			if (attributes.code !== undefined) {
				this.code = attributes.code;
			}
			if (attributes.loginBackground !== undefined) {
				this.loginBackground = attributes.loginBackground;
			}
			if (attributes.loginBackgroundId !== undefined) {
				this.loginBackgroundId = attributes.loginBackgroundId;
			}
			if (attributes.secondaryColour !== undefined) {
				this.secondaryColour = attributes.secondaryColour;
			}
			if (attributes.allowMulticampusSelection !== undefined) {
				this.allowMulticampusSelection = attributes.allowMulticampusSelection;
			}
			if (attributes.subdomain !== undefined) {
				this.subdomain = attributes.subdomain;
			}
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.logo !== undefined) {
				this.logo = attributes.logo;
			}
			if (attributes.logoId !== undefined) {
				this.logoId = attributes.logoId;
			}
			if (attributes.successColour !== undefined) {
				this.successColour = attributes.successColour;
			}
			if (attributes.warningColour !== undefined) {
				this.warningColour = attributes.warningColour;
			}
			if (attributes.contactEmail !== undefined) {
				this.contactEmail = attributes.contactEmail;
			}
			if (attributes.cricosNumber !== undefined) {
				this.cricosNumber = attributes.cricosNumber;
			}
			if (attributes.campuss !== undefined && Array.isArray(attributes.campuss)) {
				for (const model of attributes.campuss) {
					if (model instanceof Models.CampusEntity) {
						this.campuss.push(model);
					} else {
						this.campuss.push(new Models.CampusEntity(model));
					}
				}
			}
			if (attributes.courseLevels !== undefined && Array.isArray(attributes.courseLevels)) {
				for (const model of attributes.courseLevels) {
					if (model instanceof Models.CourseLevelEntity) {
						this.courseLevels.push(model);
					} else {
						this.courseLevels.push(new Models.CourseLevelEntity(model));
					}
				}
			}
			if (attributes.courses !== undefined && Array.isArray(attributes.courses)) {
				for (const model of attributes.courses) {
					if (model instanceof Models.CourseEntity) {
						this.courses.push(model);
					} else {
						this.courses.push(new Models.CourseEntity(model));
					}
				}
			}
			if (attributes.curriculumSupports !== undefined && Array.isArray(attributes.curriculumSupports)) {
				for (const model of attributes.curriculumSupports) {
					if (model instanceof Models.CurriculumSupportEntity) {
						this.curriculumSupports.push(model);
					} else {
						this.curriculumSupports.push(new Models.CurriculumSupportEntity(model));
					}
				}
			}
			if (attributes.facultys !== undefined && Array.isArray(attributes.facultys)) {
				for (const model of attributes.facultys) {
					if (model instanceof Models.FacultyEntity) {
						this.facultys.push(model);
					} else {
						this.facultys.push(new Models.FacultyEntity(model));
					}
				}
			}
			if (attributes.graduateLevelss !== undefined && Array.isArray(attributes.graduateLevelss)) {
				for (const model of attributes.graduateLevelss) {
					if (model instanceof Models.GraduateLevelEntity) {
						this.graduateLevelss.push(model);
					} else {
						this.graduateLevelss.push(new Models.GraduateLevelEntity(model));
					}
				}
			}
			if (attributes.importJobs !== undefined && Array.isArray(attributes.importJobs)) {
				for (const model of attributes.importJobs) {
					if (model instanceof Models.ImportJobEntity) {
						this.importJobs.push(model);
					} else {
						this.importJobs.push(new Models.ImportJobEntity(model));
					}
				}
			}
			if (attributes.institutionAdmins !== undefined && Array.isArray(attributes.institutionAdmins)) {
				for (const model of attributes.institutionAdmins) {
					if (model instanceof Models.InstitutionAdminEntity) {
						this.institutionAdmins.push(model);
					} else {
						this.institutionAdmins.push(new Models.InstitutionAdminEntity(model));
					}
				}
			}
			if (attributes.studentAdvisors !== undefined && Array.isArray(attributes.studentAdvisors)) {
				for (const model of attributes.studentAdvisors) {
					if (model instanceof Models.StudentAdvisorEntity) {
						this.studentAdvisors.push(model);
					} else {
						this.studentAdvisors.push(new Models.StudentAdvisorEntity(model));
					}
				}
			}
			if (attributes.studyPeriodss !== undefined && Array.isArray(attributes.studyPeriodss)) {
				for (const model of attributes.studyPeriodss) {
					if (model instanceof Models.StudyPeriodEntity) {
						this.studyPeriodss.push(model);
					} else {
						this.studyPeriodss.push(new Models.StudyPeriodEntity(model));
					}
				}
			}
			if (attributes.users !== undefined && Array.isArray(attributes.users)) {
				for (const model of attributes.users) {
					if (model instanceof Models.UserEntity) {
						this.users.push(model);
					} else {
						this.users.push(new Models.UserEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		campuss {
			${Models.CampusEntity.getAttributes().join('\n')}
		}
		courseLevels {
			${Models.CourseLevelEntity.getAttributes().join('\n')}
		}
		courses {
			${Models.CourseEntity.getAttributes().join('\n')}
		}
		curriculumSupports {
			${Models.CurriculumSupportEntity.getAttributes().join('\n')}
		}
		facultys {
			${Models.FacultyEntity.getAttributes().join('\n')}
		}
		graduateLevelss {
			${Models.GraduateLevelEntity.getAttributes().join('\n')}
		}
		importJobs {
			${Models.ImportJobEntity.getAttributes().join('\n')}
			${Models.ImportJobEntity.getFiles().map(f => f.name).join('\n')}
		}
		institutionAdmins {
			${Models.InstitutionAdminEntity.getAttributes().join('\n')}
		}
		studentAdvisors {
			${Models.StudentAdvisorEntity.getAttributes().join('\n')}
		}
		studyPeriodss {
			${Models.StudyPeriodEntity.getAttributes().join('\n')}
		}
		users {
			${Models.UserEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			campuss: {},
			courseLevels: {},
			courses: {},
			curriculumSupports: {},
			facultys: {},
			graduateLevelss: {},
			importJobs: {},
			institutionAdmins: {},
			studentAdvisors: {},
			studyPeriodss: {},
			users: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
				contentType: 'multipart/form-data',
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] on begin
		return this.name;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(InstitutionEntity.prototype, 'created');
CRUD(modifiedAttr)(InstitutionEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
