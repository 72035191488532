/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import CurriculumSupportProgramRuleEntity from 'Models/Security/Acl/CurriculumSupportProgramRuleEntity';
import AdminProgramRuleEntity from 'Models/Security/Acl/AdminProgramRuleEntity';
import InstitutionAdminProgramRuleEntity from 'Models/Security/Acl/InstitutionAdminProgramRuleEntity';
import StudentAdvisorProgramRuleEntity from 'Models/Security/Acl/StudentAdvisorProgramRuleEntity';
import UserProgramRuleEntity from 'Models/Security/Acl/UserProgramRuleEntity';
import VisitorsProgramRuleEntity from 'Models/Security/Acl/VisitorsProgramRuleEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] off begin
// % protected region % [Add any further imports here] end

export interface IProgramRuleEntityAttributes extends IModelAttributes {
	name: string;
	displayName: string;
	description: string;
	order: number;
	displayOnSidebar: boolean;
	category: Enums.requirementCategory;
	compulsory: boolean;
	condition: Enums.requirementCondition;

	courses: Array<
		| Models.ProgramRuleCourse
		| Models.IProgramRuleCourseAttributes
	>;
	parentProgramRuleId?: string;
	parentProgramRule?: Models.ProgramRuleEntity | Models.IProgramRuleEntityAttributes;
	programRules: Array<
		| Models.ProgramRuleEntity
		| Models.IProgramRuleEntityAttributes
	>;
	programVersionId?: string;
	programVersion?: Models.ProgramVersionEntity | Models.IProgramVersionEntityAttributes;
	rootProgramVersionId?: string;
	rootProgramVersion?: Models.ProgramVersionEntity | Models.IProgramVersionEntityAttributes;
	unitConditions: Array<
		| Models.UnitConditionEntity
		| Models.IUnitConditionEntityAttributes
	>;
	// % protected region % [Add any custom attributes to the interface here] off begin
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('ProgramRuleEntity', 'Program Rule')
// % protected region % [Customise your entity metadata here] end
export default class ProgramRuleEntity extends Model
	implements IProgramRuleEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new CurriculumSupportProgramRuleEntity(),
		new AdminProgramRuleEntity(),
		new InstitutionAdminProgramRuleEntity(),
		new StudentAdvisorProgramRuleEntity(),
		new UserProgramRuleEntity(),
		new VisitorsProgramRuleEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Name'] off begin
	@Validators.Required()
	@observable
	@attribute<ProgramRuleEntity, string>()
	@CRUD({
		name: 'Name',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public name: string;
	// % protected region % [Modify props to the crud options here for attribute 'Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Display Name'] off begin
	@Validators.Required()
	@observable
	@attribute<ProgramRuleEntity, string>()
	@CRUD({
		name: 'Display Name',
		displayType: 'textfield',
		order: 20,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public displayName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Display Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Description'] off begin
	@observable
	@attribute<ProgramRuleEntity, string>()
	@CRUD({
		name: 'Description',
		displayType: 'textfield',
		order: 30,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public description: string;
	// % protected region % [Modify props to the crud options here for attribute 'Description'] end

	// % protected region % [Modify props to the crud options here for attribute 'Order'] off begin
	@Validators.Integer()
	@observable
	@attribute<ProgramRuleEntity, number>()
	@CRUD({
		name: 'Order',
		displayType: 'textfield',
		order: 40,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public order: number;
	// % protected region % [Modify props to the crud options here for attribute 'Order'] end

	// % protected region % [Modify props to the crud options here for attribute 'Display On Sidebar'] off begin
	@Validators.Required()
	@observable
	@attribute<ProgramRuleEntity, boolean>()
	@CRUD({
		name: 'Display On Sidebar',
		displayType: 'checkbox',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public displayOnSidebar: boolean = false;
	// % protected region % [Modify props to the crud options here for attribute 'Display On Sidebar'] end

	// % protected region % [Modify props to the crud options here for attribute 'Category'] off begin
	@Validators.Required()
	@observable
	@attribute<ProgramRuleEntity, Enums.requirementCategory>()
	@CRUD({
		name: 'Category',
		displayType: 'enum-combobox',
		order: 60,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.requirementCategoryOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.requirementCategoryOptions),
		displayFunction: (attr: Enums.requirementCategory) => Enums.requirementCategoryOptions[attr],
	})
	public category: Enums.requirementCategory;
	// % protected region % [Modify props to the crud options here for attribute 'Category'] end

	// % protected region % [Modify props to the crud options here for attribute 'Compulsory'] off begin
	@Validators.Required()
	@observable
	@attribute<ProgramRuleEntity, boolean>()
	@CRUD({
		name: 'Compulsory',
		displayType: 'checkbox',
		order: 70,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public compulsory: boolean = false;
	// % protected region % [Modify props to the crud options here for attribute 'Compulsory'] end

	// % protected region % [Modify props to the crud options here for attribute 'Condition'] off begin
	@Validators.Required()
	@observable
	@attribute<ProgramRuleEntity, Enums.requirementCondition>()
	@CRUD({
		name: 'Condition',
		displayType: 'enum-combobox',
		order: 80,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.requirementConditionOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.requirementConditionOptions),
		displayFunction: (attr: Enums.requirementCondition) => Enums.requirementConditionOptions[attr],
	})
	public condition: Enums.requirementCondition;
	// % protected region % [Modify props to the crud options here for attribute 'Condition'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Course'] off begin
		name: 'Course',
		displayType: 'reference-multicombobox',
		order: 90,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.ProgramRuleCourse,
		optionEqualFunc: makeJoinEqualsFunc('courseId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'programRuleEntity',
			oppositeEntityName: 'courseEntity',
			relationName: 'programRule',
			relationOppositeName: 'course',
			entity: () => Models.ProgramRuleEntity,
			joinEntity: () => Models.ProgramRuleCourse,
			oppositeEntity: () => Models.CourseEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Course'] end
	})
	public courses: Models.ProgramRuleCourse[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Parent Program Rule'] off begin
		name: 'Parent Program Rule',
		displayType: 'reference-combobox',
		order: 100,
		referenceTypeFunc: () => Models.ProgramRuleEntity,
		// % protected region % [Modify props to the crud options here for reference 'Parent Program Rule'] end
	})
	public parentProgramRuleId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public parentProgramRule: Models.ProgramRuleEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Program Rule'] off begin
		name: 'Program Rules',
		displayType: 'reference-multicombobox',
		order: 110,
		referenceTypeFunc: () => Models.ProgramRuleEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'programRules',
			oppositeEntity: () => Models.ProgramRuleEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Program Rule'] end
	})
	public programRules: Models.ProgramRuleEntity[] = [];

	/**
	 * All program rules of a program version. All program rules should link to a program version
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Program Version'] off begin
		name: 'Program Version',
		displayType: 'reference-combobox',
		order: 120,
		referenceTypeFunc: () => Models.ProgramVersionEntity,
		// % protected region % [Modify props to the crud options here for reference 'Program Version'] end
	})
	public programVersionId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public programVersion: Models.ProgramVersionEntity;

	/**
	 * The top most program rule
	 */
	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Root Program Version'] off begin
		name: 'Root Program Version',
		displayType: 'reference-combobox',
		order: 130,
		referenceTypeFunc: () => Models.ProgramVersionEntity,
		// % protected region % [Modify props to the crud options here for reference 'Root Program Version'] end
	})
	public rootProgramVersionId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public rootProgramVersion: Models.ProgramVersionEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Unit Condition'] off begin
		name: 'Unit Conditions',
		displayType: 'reference-multicombobox',
		order: 140,
		referenceTypeFunc: () => Models.UnitConditionEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'unitConditions',
			oppositeEntity: () => Models.UnitConditionEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Unit Condition'] end
	})
	public unitConditions: Models.UnitConditionEntity[] = [];

	// % protected region % [Add any custom attributes to the model here] off begin
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IProgramRuleEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IProgramRuleEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.name !== undefined) {
				this.name = attributes.name;
			}
			if (attributes.displayName !== undefined) {
				this.displayName = attributes.displayName;
			}
			if (attributes.description !== undefined) {
				this.description = attributes.description;
			}
			if (attributes.order !== undefined) {
				this.order = attributes.order;
			}
			if (attributes.displayOnSidebar !== undefined) {
				this.displayOnSidebar = attributes.displayOnSidebar;
			}
			if (attributes.category !== undefined) {
				this.category = attributes.category;
			}
			if (attributes.compulsory !== undefined) {
				this.compulsory = attributes.compulsory;
			}
			if (attributes.condition !== undefined) {
				this.condition = attributes.condition;
			}
			if (attributes.courses !== undefined && Array.isArray(attributes.courses)) {
				for (const model of attributes.courses) {
					if (model instanceof Models.ProgramRuleCourse) {
						this.courses.push(model);
					} else {
						this.courses.push(new Models.ProgramRuleCourse(model));
					}
				}
			}
			if (attributes.programRules !== undefined && Array.isArray(attributes.programRules)) {
				for (const model of attributes.programRules) {
					if (model instanceof Models.ProgramRuleEntity) {
						this.programRules.push(model);
					} else {
						this.programRules.push(new Models.ProgramRuleEntity(model));
					}
				}
			}
			if (attributes.parentProgramRuleId !== undefined) {
				this.parentProgramRuleId = attributes.parentProgramRuleId;
			}
			if (attributes.parentProgramRule !== undefined) {
				if (attributes.parentProgramRule === null) {
					this.parentProgramRule = attributes.parentProgramRule;
				} else if (attributes.parentProgramRule instanceof Models.ProgramRuleEntity) {
					this.parentProgramRule = attributes.parentProgramRule;
					this.parentProgramRuleId = attributes.parentProgramRule.id;
				} else {
					this.parentProgramRule = new Models.ProgramRuleEntity(attributes.parentProgramRule);
					this.parentProgramRuleId = this.parentProgramRule.id;
				}
			}
			if (attributes.programVersionId !== undefined) {
				this.programVersionId = attributes.programVersionId;
			}
			if (attributes.programVersion !== undefined) {
				if (attributes.programVersion === null) {
					this.programVersion = attributes.programVersion;
				} else if (attributes.programVersion instanceof Models.ProgramVersionEntity) {
					this.programVersion = attributes.programVersion;
					this.programVersionId = attributes.programVersion.id;
				} else {
					this.programVersion = new Models.ProgramVersionEntity(attributes.programVersion);
					this.programVersionId = this.programVersion.id;
				}
			}
			if (attributes.rootProgramVersionId !== undefined) {
				this.rootProgramVersionId = attributes.rootProgramVersionId;
			}
			if (attributes.rootProgramVersion !== undefined) {
				if (attributes.rootProgramVersion === null) {
					this.rootProgramVersion = attributes.rootProgramVersion;
				} else if (attributes.rootProgramVersion instanceof Models.ProgramVersionEntity) {
					this.rootProgramVersion = attributes.rootProgramVersion;
					this.rootProgramVersionId = attributes.rootProgramVersion.id;
				} else {
					this.rootProgramVersion = new Models.ProgramVersionEntity(attributes.rootProgramVersion);
					this.rootProgramVersionId = this.rootProgramVersion.id;
				}
			}
			if (attributes.unitConditions !== undefined && Array.isArray(attributes.unitConditions)) {
				for (const model of attributes.unitConditions) {
					if (model instanceof Models.UnitConditionEntity) {
						this.unitConditions.push(model);
					} else {
						this.unitConditions.push(new Models.UnitConditionEntity(model));
					}
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		courses {
			${Models.ProgramRuleCourse.getAttributes().join('\n')}
			course {
				${Models.CourseEntity.getAttributes().join('\n')}
			}
		}
		programRules {
			${Models.ProgramRuleEntity.getAttributes().join('\n')}
		}
		parentProgramRule {
			${Models.ProgramRuleEntity.getAttributes().join('\n')}
		}
		programVersion {
			${Models.ProgramVersionEntity.getAttributes().join('\n')}
		}
		rootProgramVersion {
			${Models.ProgramVersionEntity.getAttributes().join('\n')}
		}
		unitConditions {
			${Models.UnitConditionEntity.getAttributes().join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			courses: {},
			programRules: {},
			unitConditions: {},
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'courses',
							'programRules',
							'unitConditions',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.id;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] off begin
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(ProgramRuleEntity.prototype, 'created');
CRUD(modifiedAttr)(ProgramRuleEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
